.grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-auto-rows: 380px;

  &--mobile {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-auto-rows: minmax(380px, max-content);
  }
}
